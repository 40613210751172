@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Voyager';
  src: url('../public/fonts/Voyager.otf');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0 30px rgba(17, 24, 39, 0.8) inset !important;
    caret-color: white;
    border: 1px solid #ffffff;
    border-radius: 0.5rem; 
    color: #ffffff;
    transition: all 0.2s ease-in-out;
}

input:-webkit-autofill:hover {
  border-color: #3C91E6;
}

input:-webkit-autofill:focus {
  border: 1px solid rgba(37, 99, 235);
}